import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Modal from 'react-modal';
import { Link } from "react-router-dom";

import all from "../../../../assets/sept.png";

const Craft = ({ posts }) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // document.getElementById("body").style.opacity = "0.1";
      document.getElementById("modal-content2").classList.add("modal-fade-in");
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
  
  
  
  
    return  (
        <div className="third-section">

        <div className="nextship">
          <div className="ship-left">
            <p className="txt-head">The Golden Shamrock Prize</p>
            <h1 className="handbag-title" style={{fontFamily:"The new Elegance"}}>The Luxurious Holden Leathergoods Handbag

</h1>
            <div className="ship-right mobile-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img" />
            </LazyLoad>
          </div>
            <p className="txt-paragraph">I picked out this beautifully made Holden Leathergoods handbag for a lucky member. Will it be you who win it this September? When you strut down the street with this beauty, you’ll hear gasps and ‘ooh’s and aaah’s’, along with questions as to where you got it! You will have to tell them it was handmade in Dingle, Ireland, within and old schoolhouse (now the workshop) that is nestled within the ancient trees of Burnham woods.<br/>
            We will choose a winner at the beginning of October and ship it off to you from Dingle Ireland if it is indeed you who wins it.
            </p>
             <div className="handbag-display">
             <Link to={window.location.href.includes("christmas-ecard") ? "/gifting-form" :"/get-started"}  >{window.location.href.includes("christmas-ecard") ? "Gift to Enter Draw" :"Subscribe to Enter Draw"} </Link>
             <p onClick={openModal} className="hoverable">See Rules</p>
             </div>
          </div>
          <div className="ship-right desktop-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img" />
            </LazyLoad>
          </div>

          <div className="thebade" onClick={openModal} >
        <LazyLoad height={200}>
             <img src={posts.acf.modalBadge} id="badge-btn-image" alt="badgeimage"></img>
        </LazyLoad>
      </div>
        </div>

            
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        id="modal-content2"
        contentLabel="Example Modal"
      >
      <div className="draw-offer-homepage" >
        <div onClick={closeModal} id="closemodal-badge2">X</div>
            <LazyLoad height={200}>
        <img id="badge3" src={posts.acf.modalBadge} alt="badgeimage
        "></img>
        </LazyLoad>
          <p   dangerouslySetInnerHTML={{
            __html: posts.acf.modalcontent2,
          }}></p>
          <Link to="/get-started">
        <button className="modalbtn " onClick={closeModal}>
          Continue to Enter
        </button>
        </Link>
        </div>
      </Modal>

     </div>
    )
}

export default Craft;