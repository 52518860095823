import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//components
import Navbar from "../Navbar";
import Footer from "../Footer";
import MemoryLane from "../Shared/Pages/About/MemoryLane";
import OurFounder from "../Shared/Pages/About/OurFounder";
//style
import "../Shared/Styles/aboutus.css";
//images 
import bannner from "../../assets/baneri.png";
import LazyLoad from 'react-lazyload';


function Aboutus({ posts, founder, setFounder, changeTab }) {
  const [content, setContent] = useState(true);
  const showContent = () => {
    if (content === true) {
      return <MemoryLane posts={posts} />;
    } else {
      return <OurFounder posts={posts} ourFounder={founder} />;
    }
  };
  // console.log(window.location.pathname);

  useEffect(() => {
    setContent(changeTab);
    if (window.location.pathname === "/aboutus/ourfounder") {
      setFounder(false);
    }
  });
  // console.log(changeTab);
  return (
    <div>
      <Navbar posts={posts} />

      <div
        style={{ backgroundImage: `url(${bannner})` }}
        className="banner"
        id="banner"
      >
            <LazyLoad height={200}>
        <img src={bannner} alt="bannner" />
        </LazyLoad>
      </div>
      <div className="about-menu">
        <div className="about-buttons">
          <button
            className="about-button-1 hoverable"
            id={content === true ? "about-active" : ""}
            onClick={() => {
              setFounder(true);
            }}
          >
            <p className="pagechanger">MEMORY LANE</p>
          </button>
          <button
            className="about-button-2 hoverable"
            id={content === false ? "about-active" : ""}
            onClick={() => {
              setFounder(false);
            }}
          >
            <p className="pagechanger">Our Founder</p>
          </button>
        </div>
        {/* <div>
          <p className="pagechanger"> OUR FOUNDER</p>
        </div> */}
      </div>
      <>{showContent()}</>
      <div className="join" id="join">
        <h2>Ready to Join?</h2>
        <p>
          Subscribe to MyIrelandBox and experience <br /> Ireland every month!
        </p>
        <div className="join_buttons">
          <Link to="/get-started">
            <button className="join_button">Join MyIrelandBox</button>
          </Link>
          <Link to="/gifting-form">
            <button className="gift_button">Gift the Next Box</button>
          </Link>
        </div>
      </div>
      <div></div>

      <Footer />
    </div>
  );
}

export default Aboutus;
