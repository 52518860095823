import React from "react";
import "../../Styles/Homepage/secondBanner.css";
import { Link } from "react-router-dom";

import Lottie from "react-lottie-player";
import stepOne from "../../../../assets/step1.json";
import stepTwo from "../../../../assets/step2.json";
import stepThree from "../../../../assets/step3.json";


function SecondBanner({ posts }) {
  const subscribeLink =
  window.location.href.includes("cw") ? "/cw/form" :
  window.location.href.includes("/christmas-ecard") ? "/gifting-form" :
  "/get-started";
  return (
    <>
      <div className="second-section">
        <h1
          className="sbTitle"
          dangerouslySetInnerHTML={{
            __html: posts.acf.second_section.steps_heading,
          }}
        ></h1>

        <div className="how-it-works">
          <div className="hw-step">
            <div className="step-animation">
              <Lottie
                loop
                animationData={stepOne}
                play
                style={{ width: 200, height: 200 }}
              />
            </div>
            <p className="steps">Step 1</p>
            <h1 className="step-heading">
              {posts.acf.second_section.steps[0].name}
            </h1>
            <p className="step-description">
              {posts.acf.second_section.steps[0].description}
            </p>
          </div>

          <div className="hw-step">
            <div className="step-animation">
              <Lottie
                loop
                animationData={stepTwo}
                play
                style={{ width: 200, height: 200 }}
              />
            </div>
            <p className="steps">Step 2</p>
            <h1 className="step-heading">
              {posts.acf.second_section.steps[1].name}
            </h1>
            <p className="step-description">
              {posts.acf.second_section.steps[1].description}
            </p>
          </div>

          <div className="hw-step">
            <div className="step-animation">
              <Lottie
                loop
                animationData={stepThree}
                play
                style={{ width: 200, height: 200 }}
              />
            </div>
            <p className="steps">Step 3</p>

            <h1 className="step-heading">
              {posts.acf.second_section.steps[2].name}
            </h1>
            <p className="step-description">
              {posts.acf.second_section.steps[2].description}
            </p>
          </div>
        </div>

        <Link
          to={
            subscribeLink
          }
          className="subscribe "
        >
Subscribe to MyIrelandBox

        </Link>
      </div>
    </>
  );
}

export default SecondBanner;
