import React from "react";
import "../../Styles/Homepage/facebookGroup.css";
import { Link } from "react-router-dom";
const FacebookGroup = ({ posts }) => {
  const subscribeLink =
  window.location.href.includes("cw") ? "/cw/form" :
  window.location.href.includes("/christmas-ecard") ? "/gifting-form" :
  "/get-started";
  return (
    <div
      className="fbMain"
      style={{ backgroundImage: `url(${posts.acf.facebookgroup.bgimage})` }}
    >
      <div className="fbMainText">
        <div
          dangerouslySetInnerHTML={{ __html: posts.acf.facebookgroup.header }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: posts.acf.facebookgroup.paragraph,
          }}
        />
      </div>
      <div className="fbMainButtons">
        <a
          href="https://www.facebook.com/groups/MyIrelandBox"
          target="_blank"
          className="hoverable"
        >
          Join the Facebook Group
        </a>
        {window.location.pathname  === "/my-letters-from-ireland" ?
       "": <Link
       to={
        subscribeLink
       }
       className="hoverable"
     >
Get a MyIrelandBox

     </Link> }
       
      </div>
    </div>
  );
};

export default FacebookGroup;
