import React from "react";
import { Link } from "react-router-dom";
import "../Shared/Styles/freeButlers.css";

function FreeButlers({ style, posts }) {
  return (
    <div className="freeButlers" id={style}>
      {window.location.href.indexOf("cw") != -1 ||
      window.location.href.indexOf("gifters") != -1 ? (
        <p
          style={{
            width: "100% !important",
            display: "flex",
            alignItems: "center",
            marginBottom: "0",
          }}
        >
          <p>FREE Connemarra WorryStone & Buttlers Chocolate on Sign Up!</p>
        </p>
      ) : (
        <p>{posts.acf.topnotice}</p>
      )}

      <Link
        to={
          window.location.href.indexOf("cw") != -1
            ? "/cw/form"
            : window.location.href.indexOf("gifters") != -1
            ? "/gifters/form"
            : window.location.href.indexOf("gift") != -1
            ? "/gift/form"
            : "/get-started"
        }
        className="free-buttlers-link"
      >
        Subscribe
      </Link>
      {/* <div id="orange"></div> */}
    </div>
  );
}

export default FreeButlers;
